import React, { lazy, Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { connect } from 'react-redux';

import Layout from 'layouts';

const restrictedPages = ['/gam/reports/geo', '/gam/reports/devices', '/gam/reports/adunits'];

const routes = [
    //gam
    {
        path: '/gam/settings',
        Component: lazy(() => import('pages/gam-settings')),
        exact: true,
    },
    {
        path: '/gam/reports',
        Component: lazy(() => import('pages/gam-reports')),
        exact: true,
    },

    // ========== sub-gam reports ==========
    {
        path: '/gam/reports/geo',
        Component: lazy(() => import('pages/gam-reports/gam-report-dimensions/geo-gam-reports')),
        exact: true,
    },
    {
        path: '/gam/reports/devices',
        Component: lazy(() => import('pages/gam-reports/gam-report-dimensions/device-type-gam-reports')),
        exact: true,
    },
    {
        path: '/gam/reports/adunits',
        Component: lazy(() => import('pages/gam-reports/gam-report-dimensions/adunit-gam-reports')),
        exact: true,
    },
    // ========== prebid reports ===========
    // {
    //     path: '/prebid/reports',
    //     Component: lazy(() => import('pages/prebid-reports')),
    //     exact: true,
    // },
    {
        path: '/gam/reports/media-types',
        Component: lazy(() => import('pages/gam-reports/gam-report-dimensions/media-type-gam-reports')),
        exact: true,
    },
    {
        path: '/gam/order-reports',
        Component: lazy(() => import('pages/gam-order-reports')),
        exact: true,
    },
    {
        path: '/websites',
        Component: lazy(() => import('pages/websites')),
        exact: true,
    },
    {
        path: '/websites/:websiteId',
        Component: lazy(() => import('pages/impact-dashboard/')),
        exact: true,
    },
    {
        path: '/billing-summary',
        Component: lazy(() => import('pages/revenue-reports')),
        exact: true,
    },
    {
        path: '/gam/callback',
        //http://localhost:3000/gam/callback/
        Component: lazy(() => import('pages/gam-oauth-callback')),
        exact: true,
    },
    // Profile
    {
        path: '/profile',
        Component: lazy(() => import('pages/profile')),
        exact: true,
    },
    // Account Settings
    {
        path: '/gam/account-management',
        Component: lazy(() => import('pages/gam-settings/account-management')),
        exact: true,
    },
    // Widgets
    {
        path: '/widgets/general',
        Component: lazy(() => import('pages/widgets/general')),
        exact: true,
    },
    {
        path: '/widgets/lists',
        Component: lazy(() => import('pages/widgets/lists')),
        exact: true,
    },
    {
        path: '/widgets/tables',
        Component: lazy(() => import('pages/widgets/tables')),
        exact: true,
    },
    {
        path: '/widgets/charts',
        Component: lazy(() => import('pages/widgets/charts')),
        exact: true,
    },
    // Cards
    {
        path: '/cards/basic-cards',
        Component: lazy(() => import('pages/cards/basic-cards')),
        exact: true,
    },
    {
        path: '/cards/tabbed-cards',
        Component: lazy(() => import('pages/cards/tabbed-cards')),
        exact: true,
    },

    // Charts
    {
        path: '/charts/chartistjs',
        Component: lazy(() => import('pages/charts/chartistjs')),
        exact: true,
    },
    {
        path: '/charts/chartjs',
        Component: lazy(() => import('pages/charts/chartjs')),
        exact: true,
    },
    {
        path: '/charts/c3',
        Component: lazy(() => import('pages/charts/c3')),
        exact: true,
    },
    // Icons
    {
        path: '/icons/feather-icons',
        Component: lazy(() => import('pages/icons/feather-icons')),
        exact: true,
    },
    {
        path: '/icons/fontawesome',
        Component: lazy(() => import('pages/icons/fontawesome')),
        exact: true,
    },
    {
        path: '/icons/linearicons-free',
        Component: lazy(() => import('pages/icons/linearicons-free')),
        exact: true,
    },
    {
        path: '/icons/icomoon-free',
        Component: lazy(() => import('pages/icons/icomoon-free')),
        exact: true,
    },
    // Advanced
    {
        path: '/advanced/form-examples',
        Component: lazy(() => import('pages/advanced/form-examples')),
        exact: true,
    },
    {
        path: '/advanced/email-templates',
        Component: lazy(() => import('pages/advanced/email-templates')),
        exact: true,
    },
    {
        path: '/advanced/utilities',
        Component: lazy(() => import('pages/advanced/utilities')),
        exact: true,
    },
    {
        path: '/advanced/grid',
        Component: lazy(() => import('pages/advanced/grid')),
        exact: true,
    },
    {
        path: '/advanced/typography',
        Component: lazy(() => import('pages/advanced/typography')),
        exact: true,
    },
    {
        path: '/advanced/pricing-tables',
        Component: lazy(() => import('pages/advanced/pricing-tables')),
        exact: true,
    },
    {
        path: '/advanced/invoice',
        Component: lazy(() => import('pages/advanced/invoice')),
        exact: true,
    },
    {
        path: '/advanced/colors',
        Component: lazy(() => import('pages/advanced/colors')),
        exact: true,
    },
    // Auth Pages
    {
        path: '/auth/login',
        Component: lazy(() => import('pages/auth/login')),
        exact: true,
    },
    {
        path: '/auth/forgot-password',
        Component: lazy(() => import('pages/auth/forgot-password')),
        exact: true,
    },
    {
        path: '/auth/reset_password/:uid/:token',
        Component: lazy(() => import('pages/auth/reset-password/')),
        exact: true,
    },
    {
        path: '/auth/register',
        Component: lazy(() => import('pages/auth/register')),
        exact: true,
    },
    {
        path: '/auth/lockscreen',
        Component: lazy(() => import('pages/auth/lockscreen')),
        exact: true,
    },
    {
        path: '/auth/404',
        Component: lazy(() => import('pages/auth/404')),
        exact: true,
    },
    {
        path: '/auth/500',
        Component: lazy(() => import('pages/auth/500')),
        exact: true,
    },
];

const mapStateToProps = ({ settings, user }) => ({
    routerAnimation: settings.routerAnimation,
    publisher: user.publisher,
});

const Router = ({ history, routerAnimation, publisher }) => {
    const show_dimension_dashboard = publisher?.show_dimension_dashboard;
    return (
        <ConnectedRouter history={history}>
            <Layout>
                <Route
                    render={state => {
                        const { location } = state;
                        return (
                            <SwitchTransition>
                                <CSSTransition
                                    key={location.pathname}
                                    appear
                                    classNames={routerAnimation}
                                    timeout={routerAnimation === 'none' ? 0 : 300}
                                >
                                    <Switch location={location}>
                                        <Route exact path="/" render={() => <Redirect to="/gam/reports" />} />
                                        {routes.map(({ path, Component, exact }) => {
                                            if (!show_dimension_dashboard && restrictedPages.includes(path)) {
                                                return (
                                                    <Route
                                                        exact
                                                        path="/"
                                                        render={() => <Redirect to="/gam/reports" />}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <Route
                                                        path={path}
                                                        key={path}
                                                        exact={exact}
                                                        render={() => {
                                                            return (
                                                                <div className={routerAnimation}>
                                                                    <Suspense fallback={null}>
                                                                        <Component />
                                                                    </Suspense>
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                );
                                            }
                                        })}
                                        <Redirect to="/auth/404" />
                                    </Switch>
                                </CSSTransition>
                            </SwitchTransition>
                        );
                    }}
                />
            </Layout>
        </ConnectedRouter>
    );
};

export default connect(mapStateToProps)(Router);
